@import "../../index.scss";

/* common css start */
body {
    // background: $color_white;
    font-family: $monterest;
}

.landingpage-container {
    background: $color_white;
    .btn.btn-b-blue {
        border-color: $color_orange;
    }
h1 {
    font-size: $sixty - 6;
    line-height: $seventy - 6;
    color: $color_white !important;
    font-weight: $Extra-bold;
    font-family: $monterest;
}
h2 {
    font-size: $fourty + 4;
    line-height: $fifty + 2;
    color: $color_black;
    font-weight: $Extra-bold;
    font-family: $monterest;
    margin: 0;
    text-align: center;
}
h3 {
    font-size: $fourty - 4;
    line-height: $fourty + 2;
    color: $color_black2 !important;
    font-weight: $Extra-bold;
    font-family: $monterest;
    margin-bottom: 0;
    margin-top: $zero;
}
h4 {
    font-size: $thirty - 2;
    line-height: $fourty - 4;
    color: $color_black2;
    font-weight: $bold;
    font-family: $monterest;
    margin-bottom: 0;
    margin-top: $zero;
}
h5 {
    font-size: $twenty - 4;
    line-height: $twenty + 4;
    color: $color_black2;
    font-weight: $bold;
    font-family: $monterest;
    margin-bottom: 0;
    margin-top: $zero;
}

p {
    font-size: $twenty - 2;
    line-height: $thirty - 1;
    font-family: $monterest;
    font-weight: $normal;
}
.description-content {
    padding: 0 16%;
    text-align: center;
}
.orange-btn {
    background: $color_orange;
    color: $color_white;
    border-color: $color_orange;
    font-family: $monterest;
    &:before {
        background: transparent;
    }
    &:hover {
        background: $color_white;
        border-color: $color_white;
    }
}

.success-message-block {
    text-align: center;
    margin-bottom: $fourty;
    i {
        display: block;
        margin-bottom: 8px;
    }
    p {
        margin: 0;
        font-size: $twenty + 2;
        line-height: $thirty - 2;
        color: $green_color;
        font-weight: $Semi-Bold;
    }
}
.form-block {
    .form-group {
        display: block;
        margin-bottom: $fourty;
    }
    label {
            font-size: $twenty - 4;
            line-height: $twenty;
            color: $color_black;
            font-weight: $normal;
            margin-bottom: 3px;
            display: inline-block;
            position: relative;
            font-family: $Poppins;
            &.required {
                &::after {
                    content: "*";
                    position: absolute;
                    font-size: $twenty - 4;
                    color: $color_radicalred;
                    margin-left: 5px;
                }
            }
    }
    .error {
        color: $color_radicalred;
        margin-top: 7px;
    }
    .react-tel-input {
        .flag-dropdown {
            @include border-radius(8px);
            border-right: 0;
            &:after {
                content: "";
                position: absolute;
                right: 0;
                top: 14px;
                width: 1px;
                height: 20px;
                background: $color_verylightgrey;
            }
            &:focus {
                box-shadow: 0px 6px 14px #f5822029;
                border-color: #F58220;
            }
        }
        .form-control {
            width: 100%;
            height: $fifty;
            padding-left: $seventy + 5;
            @include border-radius(8px);
            color: #3f4254;
            font-size: $ten + 6;
            font-weight: $normal;
            font-family: $Poppins;
            &:focus {
                border-color: $color_verylightgrey;
                box-shadow: none;
            }
        }
        .selected-flag {
            width: $sixty + 2;
            padding-left: $twenty - 2;
            background: $color_white;
            border-radius: 8px 0 0 8px;
            margin-left: 2px;
        }
        .country-list {
            display: block;
            margin-top: 3px;
            margin-left: 0;
            margin-right: 0;
            .country {
                width: 100%;
            }
        }
    }
    .input-box-control {
        display: inline-block;
        margin-top: 15px !important;
        margin-right: 25px;
        label {
            display: inline-block;
            font-size: $ten + 4;
            color: $color_black;
            padding-right: 7px;
        }
        &.last {
            margin-right: 0;
        }
    }
    input {
            height: $fifty;
            border-color: $color_verylightgrey;
            @include border-radius(8px);
            padding: $ten;
            outline: none;
            color: #3f4254;
            font-size: $ten + 6;
            font-weight: $normal;
            font-family: $Poppins;
            &:focus {
                box-shadow: 0px 6px 14px #F5822029;
                border-color: $color_orange;
            }
    }
    textarea {
        height: 170px;
        padding: 10px;
        color: $color_black;
        font-size: $ten + 6;
        font-weight: $normal;
        resize: none;
        outline: none;
        &:focus {
            box-shadow: 0px 6px 14px #F5822029;
            border-color: $color_orange;
        }
    }
    .select-box {
            height: $fourty;
            border-color: $color_verylightgrey;
            @include border-radius(6px);
            padding: 0px $ten;
            color: $color_matterhorn;
            font-size: $ten + 4;
            font-weight: $normal;
            outline: none;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $color_verylightgrey;
            font-size: $ten + 6;
            font-weight: $normal;
            font-family: $Poppins;
    }
    ::-moz-placeholder { /* Firefox 19+ */
            color: $color_verylightgrey;
            font-size: $ten + 6;
            font-weight: $normal;
            font-family: $Poppins;
    }
    :-ms-input-placeholder { /* IE 10+ */
        color: $color_verylightgrey;
            font-size: $ten + 6;
            font-weight: $normal;
            font-family: $Poppins;
    }
    :-moz-placeholder { /* Firefox 18- */
            color: $color_verylightgrey;
            font-size: $ten + 6;
            font-weight: $normal;
            font-family: $Poppins;
    }
}

.common-slider-block {
    .owl-theme {
        .owl-dots {
            .owl-dot {
                span {
                    width: $thirty + 4;
                    height: 4px;
                    background: $color_grey9;
                    margin-top: $zero;
                    margin-bottom: $zero;
                }
                &.active {
                    span {
                        background: $color_orange;
                        width: 53px;
                    }
                }
            } 
        }
    }
    .owl-carousel {
        .owl-nav {
            position: absolute;
            top: 40%;
            width: 100%;
            display: inline-block;
            .owl-prev {
                position: absolute;
                width: 40px;
                height: 40px;
                background: $color_black;
                left: -40px;
                margin: 0;
                &:before {
                    content: "";
                    background-image: url("../../assets/images/arrow-left.png");   
                    background-repeat: no-repeat;
                    width: 9px;
                    height: 14px;
                    background-size: 9px;
                    position: absolute;
                    left: 0;
                    right: 0;
                    text-align: center;
                    margin: 0 auto;
                    top:12px;
                }
                span {
                    display: none;
                }
                &:hover {
                    background-color: $color_orange;
                }
            }
            .owl-next {
                position: absolute;
                width: 40px;
                height: 40px;
                background: $color_black;
                right: -40px;
                margin: 0;
                &:before {
                    content: "";
                    background-image: url("../../assets/images/arrow-right.png");   
                    background-repeat: no-repeat;
                    width: 9px;
                    height: 14px;
                    background-size: 9px;
                    position: absolute;
                    left: 0;
                    right: 0;
                    text-align: center;
                    margin: 0 auto;
                    top:12px;
                }
                span {
                    display: none;
                }
                &:hover {
                    background-color: $color_orange;
                }
            }
        }
    }
}

/* common css end */

.landing-page .container {
	max-width: 1440px;
	padding: 0 15px;
}

/* header start */
.header {
	border: 0;
	text-align: left;
    padding: 14px 0;
    .header-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .nav-mobile {
            display: none;
        }
    }
	.logo {
        position: relative;
        top: 3px;
		img {
			width: 225px;
		}
	}
    .hambourger-menu {
        display: none;
    }
    &.sticky-header {
        position: fixed;
        top: 0;
        background: $color_white;
        left: 0;
        right: 0;
        width: 100%;
        border-bottom: 1px solid $color_verylightgrey;
        z-index: 99;
        justify-content: center;
        .header-inner {
            max-width: 1440px;
            margin: 0 auto;
            padding: 0 15px;
        }
    }
}
.navigation {
	padding: 0 15px;
	ul {
		display: flex;
		flex-wrap: wrap;
		margin: 0;
		padding: 0;
		li {
			padding-right: $fourty;
            position: relative;
			&:last-child {
				padding-right: 0;
			}
			a {
				font-size: $twenty - 2;
				color: #1A1818;
				font-weight: 700;
                font-family: $monterest;
                padding-bottom: $thirty;
                i {
                    position: relative;
                    top: -2px;
                }
                &:hover {
                    color: $color_orange;
                }
			}
            &.signin {
                display: none;
            }
		}
        li {
            ul {
                background: $color_white;
                margin-top: $thirty - 1;
                display: none;
                min-width: 190px;
                text-align: center;
                li {
                    padding-right: $zero;
                    &:last-child {
                        margin-bottom: $zero;
                    }
                    a {
                        color: $blue;
                        padding: 10px 0;
                        display: block;
                        &:hover {
                            background: $blue;
                            color: $color_white;
                        }
                    }
                }
            }
        }
	}
    .btn {
        display: none;
    }
}
.right-button {
	.btn {
		padding: 12px 33px;
        font-family: $monterest;
        &:before {
            display: none;
        }
        &:hover {
            background: $color_black;
            border-color: $color_white;
        }
        &.book-demo-top {
            background: $color_orange;
            color: $color_white;
            &:hover {
                background: $color_black;
            }
        }
	}
	.btn.back {
		padding-left: $fourty + 1;
		padding-right: $fourty + 1;
		margin-right: $ten;
	}
    .book-demo {
        padding-left: $sixty - 2;
        padding-right: $sixty - 2;
        background-color: $color_orange;
        margin-right: $ten;
        &:hover {
            background: $color_white;
            border-color: $color_white;
            color: $color_orange;
        }
    }
    .view-plan {
        padding-left: $sixty - 4;
        padding-right: $sixty - 4;
        background-color: transparent;
        color: $color_orange;
        &:hover {
            background: $color_orange;
            border-color: $color_orange;
            color: $color_white;
        }
    }
}

/* header end */


/* banner start */
.video-block {
    position: relative;
    &:before {
        content:"";
        background-image: url("../../assets/images/upper-element.png");   
        background-repeat: no-repeat;
        position: absolute;
        top:-30px;
        left:-30px;
        width: $seventy + 5;
        height: $seventy + 5;
    }
    &:after {
        content:"";
        background-image: url("../../assets/images/bottom-element.png");   
        background-repeat: no-repeat;
        position: absolute;
        bottom:-25px;
        right:-30px;
        width: $seventy + 5;
        height: $seventy + 5;
    }
    iframe {
        width: 589px;
        height: 336px;
        opacity: 0.80;
    }
}

.banner-container {
    background: #232833;
    background-image: url("../../assets/images/banner-bg.jpg");
    background-size: 100%;
    padding: 95px 0 166px;
    .banner-inner-section {
        display: flex;
        flex-wrap: wrap;
        .banner-left-content {
            width: 50.5%;
            h1 {
                margin-bottom: 15px;
            }
            p {
                font-size: $twenty + 4;
                line-height: $thirty + 4;
                color: $color_white;
                font-family: $monterest;
                margin-bottom: $fourty;
            }
            .btn {
                font-size: $twenty - 4;
                color: $color_white;
                font-family: $monterest;
                line-height: $thirty;
                &:before {
                    background: transparent;
                    display: none;
                    border: 0;
                }
                &.book-demo {
                    &:hover {
                        color: $color_white;
                        border-color: $color_orange;
                        background: transparent;
                    }
                }
                &.view-plan {
                    color: $color_orange;
                    &:hover {
                        color: $color_white;
                    }
                }
            }
           
        }
        .banner-video {
            padding-left: 90px;
        }
    }
}

/* banner end */


/* Why us section start */
.whyus-main-section {
    margin-top: -90px;
    margin-bottom: 0;
    .whyus-block {
        background:$color_white;
        padding: 30px 40px;
        display: flex;
        flex-wrap: wrap;
        box-shadow: 0px 10px 20px #0000000F;
        .left-content {
            width: 21%;
            h3 {
                margin-bottom: $twenty - 5;
            }
            p {
                font-size: $twenty - 4;
                line-height: $twenty + 4;
                color: $color_black2;
                font-family: $monterest;
                font-weight: $normal;
                margin: 0;
            }
        }
        .whyus-listing {
            margin: 0 -15px;
            display: flex;
            flex-wrap: wrap;
            padding-left: $sixty;
            width: 79%;;
            li {
                width: 25%;
                padding: 0 15px;
                text-align: center;
                h5 {
                    font-size: $twenty - 2;
                    line-height: $twenty + 4;
                    color: $color_black2;
                    font-family: $monterest;
                    font-weight: $bold;
                    margin: 0;
                }
                i {
                    display: block;
                    margin-bottom: $ten + 5;
                    img {
                        min-height: 80px;
                    }
                }
            }
        }
    }
}

/* Why us section end */


/* kitchen design start */

.kitchen-design-solution {
    &#solutions-block {
        h2 {
            margin-bottom: $thirty;
            margin-top: 106px;
        }
    }
    p {
        text-align: center;
        padding: 0 16%;
        margin-top: $zero;
        margin-bottom: $fifty;
    }
    .kitchen-solution {
        display: flex;
        flex-wrap: wrap;
        .kitchen-left-content {
            width: 50%;
        }
        .kitchen-right-content {
            width: 50%;
            text-align: left;
            padding-left: $sixty;
            h4 {
                margin-bottom: $twenty;
                font-weight: $Extra-bold;
                font-size: $twenty + 4;
            }
            p {
                padding: 0;
                text-align: left;
                font-size: $ten + 4;
                margin-bottom: $thirty;
            }
            .btn {
                margin-top: $ten + 5;
                display: inline-block;
                padding: 15px 38px;
                line-height: $twenty;
                &:hover {
                    background: $color_black;
                    color: $color_white;
                }
            }
        }
    }
}

/* kitchen design end */



/* work section start */
.work-container {
    margin: 120px 0;
    h2 {
        margin-bottom: $thirty;
    }
    .description-content {
        margin-bottom: $thirty;
    }
    .work-inner-block {
        box-shadow: 0px 10px 20px #0000000F;
        background: $color_white;
        padding: $ten - 3;
        h5 {
            font-size: $twenty;
            padding: 15px 12px;
        }
    }
    .item {
        padding: 20px 10px;
    }
    .orange-btn {
        max-width: 260px;
        width: 100%;
        display: block;
        margin: 40px auto 0;
        line-height: 35px;
        &:hover {
            background: $color_black;
        }
    }
}


/* Kitchen design block start */
.kitchen-design-block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.trust-kitchen-design {
    background: $blue;
    padding: 40px 0;
    margin-top: $sixty;
    .left-design-block {
        width: 50%;
        h2 {
            color: $color_white;
            padding-right: 150px;
            margin-bottom: $fourty;
            text-align: left;
        }
        p {
            color: $color_white;
            font-weight: $normal;
            margin: 0 0 50px 0;
            padding-right: $thirty;
        }
        .btn {
            &:before {
                display: none;
            }
        }
        .book-demo {
            color: $color_white;
            padding: 14px 51px;
            &:hover {
                color: $color_white;
                background: transparent;
                border-color: $color_orange;
            }
        }
        .view-plan {
            border: 0;
            padding: 0 0 0 30px;
            font-size: $twenty - 4;
            &:hover {
                background: transparent;
            }
            i {
                padding-left: 6px;
            }
        }
    }
    .right-design {
        width: 50%;
        iframe {
            width: 100%;
            height: 396px;
        }
    }
}
/* Kitchen design block end */


/* 3d design view start */
.design-view-container {
    .design-inner-section {
        display: flex;
        flex-wrap: wrap;
        padding: 140px 0 120px;
        margin: 0 -15px;
        .inside-design {
            width: 50%;
            padding: 0 15px;
            position: relative;
            a {
                display: block;
                box-shadow: 0px 10px 15px #00000014;
                transition:0.5s ease;
                -webkit-transition:0.5s ease;
                -o-transition:0.5s ease;
                -moz-transition:0.5s ease;
                -ms-transition:0.5s ease;
                &:hover {
                    span {
                        background: $blue;
                        transition:0.5s ease;
                        -webkit-transition:0.5s ease;
                        -o-transition:0.5s ease;
                        -moz-transition:0.5s ease;
                        -ms-transition:0.5s ease;
                    }
                }
            }
            span {
                font-size: $twenty - 4;
                line-height: $twenty;
                letter-spacing: 0.96px;
                color: $color_white;
                display: inline-block;
                padding: 18px 23px;
                text-transform: uppercase;
                background: $color_orange;
                position: absolute;
                top: -26px;
                left: 0;
                right: 0;
                text-align: center;
                width: auto;
                max-width: 460px;
                margin: 0 auto;
                font-weight: $bold;
                font-family: $monterest;
                transition:0.5s ease;
                -webkit-transition:0.5s ease;
                -o-transition:0.5s ease;
                -moz-transition:0.5s ease;
                -ms-transition:0.5s ease;
            }
            &.right-design-view {
                span {
                    max-width: 550px;
                }
            }
        }
    }
}

/* 3d design view end */


/* design plan section start */
.design-plans-section {
    margin-bottom: 108px;
    h2 {
        text-align: center;
    }
    p {
        text-align: center;
        padding: 0 16%;
        margin-bottom: $fourty;
    }
    .design-inner-plans {
        display: grid;
        grid-auto-columns: auto;
        gap: 0;
        ul {
            display: flex;
            flex-wrap: wrap;
            li {
                width: 33.33%;
                padding-right: $twenty + 5;
                &:last-child {
                    padding-right: $zero;
                }
                .plans-inner-block {
                    background: $peach_color;
                    padding: $thirty;
                    border-radius: 8px;
                    border: 1px solid $peach_dark;
                    height: 100%;
                    box-shadow: 0px 10px 20px #0000000F;
                    ul {
                        display: block;
                        min-height: 180px;
                        li {
                            font-size: $twenty - 4;
                            line-height: $twenty + 4;
                            color: $color_grey8;
                            margin-bottom: $twenty - 5;
                            width: 100%;
                            padding-right: $zero;
                            font-weight: $Semi-Bold;
                            position: relative;
                            padding-left: $ten + 4;
                            &:last-child {
                                margin-bottom: $zero;
                            }
                            &:before {
                                content: "";
                                width: 6px;
                                height: 6px;
                                background: $color_orange;
                                @include border-radius($ten - 4);
                                position: absolute;
                                top:$ten - 2;
                                left:0;
                            }
                        }
                    }
                    h4 {
                        font-weight: $Extra-bold;
                        margin-bottom: $twenty;
                        display: inline-block;
                        position: relative;
                        z-index: 1;
                        padding: 0 5px;
                        &:after {
                            content: "";
                            height: $ten;
                            background: $color_orangelight;
                            position: absolute;
                            bottom: 2px;
                            left: 3px;
                            width: 100%;
                            z-index: -1;
                        }
                    }
                    strong {
                        font-size: $sixty - 2;
                        line-height: $sixty - 2;
                        color: $color_black;
                        font-weight: $Extra-bold;
                        display: block;
                        sub {
                            font-size: $twenty + 4;
                            font-weight: $Semi-Bold;
                            line-height: 0;
                            position: relative;
                            bottom: $twenty - 6;
                            padding-right: 5px;
                        }
                    }
                    span {
                        font-size: $twenty + 2;
                        line-height: $thirty;
                        color: $color_black;
                        font-weight: $Extra-bold;
                        display: block;
                        margin-bottom: $twenty + 5;
                    }
                    .btn {
                        margin-top: $seventy;
                        display: inline-block;
                        line-height: $thirty;
                        &:hover {
                            background: $color_black;
                        }
                    }
                }
            }
        }
    }
}
/* design plan section end */


/* how it work start */
.howit-work-section {
    display: grid;
    grid-auto-columns: revert;
    gap: 0;
    margin-bottom: 110px;
    p {
        padding: 0 16%;
        text-align: center;
        a {
            color: $color_orange;
        }
    }
    .howwork-block {
        margin: 30px -15px 40px;
        display: flex;
        flex-wrap: wrap;
        li {
            width: 33.33%;
            padding: 0 15px;
            margin-bottom: $twenty + 5;
            .work-listing {
                background: $color_white;
                box-shadow: 0px 10px 15px #00000014;
                height: 100%;
                .listingbg-img {
                    background-image: url("../../assets/images/howwork-bg.png");   
                    min-height: 334px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }
                .work-title {
                    padding: $thirty;
                    position: relative;
                    span {
                        width: $fifty - 3;
                        height: $fifty - 3;
                        line-height: $fifty - 3;
                        background: $color_black;
                        font-weight: $bold;
                        position: absolute;
                        top:-24px;
                        left:0;
                        font-size: $thirty - 6;
                        color: $color_white;
                        display: block;
                        text-align: center;
                        font-style: italic;
                    }
                }
            }
        }
    }
    .orange-btn {
        max-width: 250px;
        margin: 0 auto;
        display: block;
        height: 50px;
        line-height: 35px;
        &:hover {
            background: $color_black;
        }
    }
}


/* schedule-form-block start */

.schedule-demo-section {
    margin-bottom: 90px;
    .schedule-bg {
        background: $blue;
        padding: 180px 0;
    }
    .form-outer {
        background: $color_white;
        padding: 50px 80px 120px;
        box-shadow: 0px 10px 20px #0000000F;
    }
    .schedule-form-block {
        margin-top: -21%;
        h2 {
            margin-bottom: $fourty;
            color: $color_white;
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -20px;
            li {
                width: 33.33%;
                padding: 0 20px;
                &:last-child {
                    width: 100%;
                }
            }
        }
        .orange-btn {
            padding: 12px 64px;
            font-size: $ten + 4;
            float: right;
            line-height: 27px;
            &:hover {
                background: $color_black;
            }
        }
    }
}
/* schedule-form-block end */


/* testimonials */

.testimonial-container {
    padding-bottom: 150px;
    .container {
        max-width: 980px;
    }
    .test-descr {
        text-align: center;
        margin-bottom: 80px;
    }
    .client-slider {
        position: relative;
        .quote-block {
            position: absolute;
            top: -30px;
            left: 110px;
            z-index: 2;
        }
    }
    .client-testimonials {
        background: $color_grey10;
        padding: 80px 20px 105px 130px;
        max-width: 740px; 
        float: right;
        position: relative;
        .author-img {
            position: absolute;
            top: 68px;
            left: -118px;
        }
        i {
            display: block;
            img {
                width: auto;
            }
        }
        p {
            font-size: $twenty - 4;
            line-height: $thirty - 2;
            color: $color_black;
            margin-bottom: $thirty;
        }
        .author-details {
            strong {
                font-size: $ten + 4;
                line-height: $twenty;
                color: $dark_black;
                display: inline-block;
                font-weight: $bold;
                padding-right: $ten;
            }
            span {
                font-size: $ten + 4;
                line-height: $twenty;
                color: $dark_black;
                display: inline-block;
                font-weight: $normal;
            }
        }
    }
    .owl-carousel {
        .owl-nav {
            display: none;
        }
        .owl-dots {
            position: absolute;
            right: 0;
            bottom: -40px;
        }
    }
}


/* footer */
.footer-container {
    background: $blue;
    .footer-top {
        border-bottom: 1px solid $blue3;
        padding: 60px 0 40px;
    }
    .footer-top-outer {
        display: flex;
        flex-wrap: wrap;
        h4 {
            color: $color_white;
            margin-bottom: $twenty;
        }
        p {
            font-size: $twenty - 4;
            line-height: $twenty + 6;
            color: $color_white;
            font-weight: $Semi-Bold;
            margin-top: $zero;
            span {
                color: $color_orange;
            }
        }
        .footer-column {
            width: 50%;
            padding: 0 15px;
            a {
                color: $color_white;
                &:hover {
                    color: $color_orange;
                }
            }
            ul {
                li {
                    margin-bottom: $ten + 5;
                    a {
                        font-size: $twenty - 4;
                        line-height: $twenty;
                        color: $color_white;
                        font-weight: $Semi-Bold;
                    }
                    &:last-child {
                        margin-bottom: $zero;
                    }
                }
                &.social-list {
                    display: flex;
                    flex-wrap: wrap;
                    li {
                        margin-right: 8px;
                        margin-bottom: $zero;
                        a {
                            width: $fifty + 1;
                            height: $fifty + 1;
                            background-color: $blue2;
                            display: block;
                            border-radius: 100%;
                            text-align: center;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            &:hover {
                                background-color: $color_orange;
                            }
                        }
                        &:last-child {
                            margin-right: $zero;
                        }
                    }
                }
            }
        }
    }
    .footer-bottom {
        padding: 40px 15px;
        background: #181828;
        .footer-bottom-outer {
            display: flex;
            flex-wrap: wrap;
            .footer-col1, .footer-col3 {
                width: 22.5%;
                padding: 0 15px;
                p {
                    font-size: $ten + 4;
                    line-height: $twenty + 5;
                    color: $drak_blue2;
                    text-align: left;
                }
            }
            .footer-col2 {
                width: 55%;
            }
            .social-icons {
                display: flex;
                align-items: center;
                li {
                    margin-right: 10px;
                    img {
                        width: 20px;
                        height: 20px;
                        &.hover-img {
                            display: none;
                        }
                    }
                    &:first-child {
                        margin-right: 3px;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        &:hover {
                            img{
                                display: none;
                                &.hover-img {
                                    display: inline-block;
                                }
                            }
                        }
                    }
                    &.instagram-img {
                        img {
                            width: 16px;
                            height: 16px;
                        }
                    }
                    &.twitter-img {
                        img {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
            .footer-nav {
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    li {
                        padding: 0 12px;
                        border-left: 1px solid #232234;
                        border-right: 1px solid #333146;
                        line-height: 14px;
                        margin-bottom: $ten + 3;
                        &:first-child {
                            border-left: 0;
                        }
                        &:last-child {
                            border-right: 0;
                        }
                        a {
                            font-size: $ten + 4;
                            line-height: $ten + 4;
                            color: $drak_blue2;
                            display: inline-block;
                            &:hover {
                                color: $color_orange;
                            }
                        }
                    }
                }
            }
        }
        p {
            font-size: $ten + 3;
            line-height: $twenty - 3;
            color: $color_grey7;
            margin: $zero;
            font-weight: $normal;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            .ktlogo {
                margin-left: 20px;
                background-color: #fff;
                padding: 3px 5px;
                img {
                    width: 135px;
                }
            }
        }
    }
}
/* footer */



ul.tabs {
	margin: 0;
	padding: 0;
	float: left;
	list-style: none;
	height: auto;
	border-bottom: 0;
	width: 100%;
    margin-bottom: 75px;
}

ul.tabs li {
    box-shadow: 0px 10px 20px #0000000f;
    float: left;
    margin: 0;
    cursor: pointer;
    padding: 0px 29px;
    height: $sixty + 2;
    line-height: 62px;
    background-color: $color_white;
    color: $color_orange;
    position: relative;
    border: 0;
    border-radius: 6px;
    font-size: $twenty;
    margin-right: $twenty;
    font-weight: $Extra-bold;
    transition:0.5s ease;
    -webkit-transition:0.5s ease;
    -o-transition:0.5s ease;
    -moz-transition:0.5s ease;
    -ms-transition:0.5s ease;

    &:last-child {
        margin-right: $zero;
    }
}

.tab_last { border-right: 1px solid #333; }

ul.tabs li:hover {
	background-color: $color_black;
	color: $color_white;
    transition:0.5s ease;
    -webkit-transition:0.5s ease;
    -o-transition:0.5s ease;
    -moz-transition:0.5s ease;
    -ms-transition:0.5s ease;
    &:before {
        content: "";
        width: 12px;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 6px solid $color_black;
        position: absolute;
        left: 0;
        bottom: -6px;
        right: 0;
        text-align: center;
        margin: 0 auto;
        transition:0.5s ease;
        -webkit-transition:0.5s ease;
        -o-transition:0.5s ease;
        -moz-transition:0.5s ease;
        -ms-transition:0.5s ease;
    }
}

ul.tabs li.active {
	background-color: $color_black;
	color: $color_white;
	border-bottom: 1px solid $color_black;
	display: block;
    &:before {
        content: "";
        width: 12px;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 6px solid $color_black;
        position: absolute;
        left: 0;
        bottom: -7px;
        right: 0;
        text-align: center;
        margin: 0 auto;
    }
}

.tab_container {
	border: 0;
	border-top: none;
	clear: both;
	float: left;
	width: 100%;
	background: #fff;
	overflow: auto;
    .tab_content {
        padding-top: $ten + 5;
        padding-bottom: $ten + 5;
    }
}

.tab_content {
	display: none;
}

.tab_drawer_heading { display: none; }

@media screen and (max-width: 768px) {
	.tabs {
		display: none;
	}
	.tab_drawer_heading {
        box-shadow: 0px 10px 20px #0000000f;
		padding: 0px 20px;
        height: $fifty;
        line-height: $fifty;
        background-color: $color_white;
        color: $color_orange !important;
        border-radius: 6px;
        font-size: $twenty;
		margin: 0 0 10px 0;
		display: block;
		cursor: pointer;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
        border: 1px solid $color_orange;
	}
	.d_active {
		background-color: $color_black;
        color: $color_white !important;
        border-color: $color_black;
        display: block;
        &:before {
            content: "";
            width: 12px;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 6px solid $color_black;
            position: absolute;
            left: 0;
            bottom: -7px;
            right: 0;
            text-align: center;
            margin: 0 auto;
        }
	}
}





/* responsive media start */

@media (max-width:1440px) {
    
        .banner-container {
            .banner-inner-section {
                .banner-video {
                    width: 47.5%;
                    padding-left: 80px;
                    iframe {
                        width: 100%;
                    }
                }
            }
        }
        .whyus-main-section {
            margin-top: -106px;
        }
        ul.tabs {
            li {
                padding: 0 20px;
            }
        }
        .common-slider-block {
            .owl-carousel {
                .owl-nav {
                    .owl-next {
                        right: -15px;
                    }
                    .owl-prev {
                        left: -15px;
                    }
                }
            }
        }
 
}


@media (min-width:768px) {
    .navigation {
        ul {
            li {
                &:hover {
                    .submenu {
                        display: block;
                    }
                }
                ul {
                    position: absolute;
                    top: 100%;
                }
            }
        }
    }
}




@media (max-width:$device__xll) {
    h1 {
        font-size: $fourty;
        line-height: $fourty + 8;
    }
    h2 {
        font-size: $thirty + 2;
        line-height: $fourty;
    }
    h3 {
        font-size: $thirty;
        line-height: $thirty + 8;
    }
    h4 {
        font-size: $twenty + 4;
        line-height: $thirty;
    }
    .form-block {
        textarea {
            height: 100px;
        }
    }
    .header {
        flex-wrap: wrap;
        .logo {
            img {
                width: 205px;
            }
        }
        .navigation {
            ul {
                li {
                    padding-right: $twenty;
                    a {
                        font-size: $ten + 6;
                    }
                }
            }
        }
        .right-button {
            margin: 0;
            width: auto;
            display: flex;
            justify-content: end;
            .btn {
                width: auto;
                display: inline-block;
                line-height: $thirty - 2;
            }
        }
    }
    .kitchen-design-solution {
        .kitchen-solution {
            .kitchen-right-content p {
                margin-bottom: $ten + 5;
            }
        }
    }
    .work-container {
        margin: $sixty 0;
    }
    .banner-container {
        padding: 71px 0 130px;
        .banner-inner-section {
            .banner-left-content {
                width: 50%;
                padding-right: 30px;
                p {
                    font-size: $twenty;
                    line-height: $twenty + 8;
                }
            }
            .banner-video {
                padding-left: $ten + 5;
                width: 48%;
                padding-right: $ten + 5;
                iframe {
                    width: 100%;
                }
            }
        }
    }
    .video-block {
        &:after {
            right: -30px;
        }
    }
    .whyus-main-section {
        .whyus-block {
            .whyus-listing {
                padding-left: $thirty;
            }
        }
    }
    ul.tabs {
        li {
            font-size: $twenty - 4;
            margin-right: $ten;
            padding: 0px 9px;
            height: 52px;
            line-height: 52px;
        }
    }
    .common-slider-block {
        .owl-carousel {
            .owl-nav {
                .owl-next {
                    right: 0;
                }
                .owl-prev {
                    left: 0;
                }
            }
        }
    }
    .howit-work-section {
        margin-bottom: $fourty;
    }
    .trust-kitchen-design {
        .left-design-block {
            h2 {
                padding-right: $twenty;
            }
        }
        .right-design {
            width: 48%;
        }
    }
    .design-view-container {
        .design-inner-section {
            padding: 70px 0;
            .inside-design.right-design-view {
                span {
                    max-width: 400px;
                }
            } 
            .inside-design {
                span {
                    max-width: 380px;
                    font-size: $ten + 4;
                    padding: 10px;
                }
            } 
        }
    }
    .schedule-demo-section {
        margin-bottom: $sixty;
        .schedule-bg {
            padding: 130px 0;
        }
    }
    .design-plans-section {
        margin-bottom: $sixty - 2;
        .design-inner-plans {
            ul {
                li {
                    .plans-inner-block {
                        strong {
                            font-size: $fourty - 2;
                            line-height: $fourty - 2;
                        }
                        .btn {
                            margin-top: $fourty;
                        }
                    } 
                }
            }
        }
    }
    .footer-container {
        .footer-top-outer {
            .footer-column {
                ul.social-list {
                    li {
                        a {
                            width: $fourty;
                            height: $fourty;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:$device__l) {
    .header {
        .header-inner {
            flex-wrap: wrap;
        }
        .right-button {
            width: 100%;
            .btn {
                width: 50%;
            }
        }
    }
    .banner-container {
        .banner-inner-section {
            .banner-left-content {
                width: 100%;
                padding-right: 0;
                p {
                    font-size: $twenty - 2;
                    line-height: $twenty + 4;
                    margin-bottom: $twenty;
                }
                .btn {
                    display: block;
                    margin-bottom: $ten + 5;
                    margin-right: 0;
                }
            }
            .banner-video {
                width: 100%;
                padding-top: 100px;
            }
        }
    }
    .whyus-main-section {
        .whyus-block {
            .left-content {
                h3 {
                    font-size: $twenty + 6;
                }
            }
            .whyus-listing {
                li {
                    h5 {
                        font-size: $ten + 6;
                    }
                }
            }
        }
    }
    ul.tabs {
        li {
            margin-bottom: $ten + 5;
        }
    }
    .trust-kitchen-design {
        .left-design-block {
            p {
                padding-right: $twenty;
            }
            .book-demo {
                padding-left: $thirty;
                padding-right: $thirty;
            }
        } 
    }
    .design-view-container {
        .design-inner-section {
            margin: $zero;
            .inside-design {
                width: 100%;
                padding: $zero;
                margin-bottom: $seventy;
                img {
                    width: 100%;
                    display: block;
                }
                &:last-child {
                    margin-bottom: $zero;
                }
                &.right-design-view {
                    span {
                        max-width: 500px;
                    }
                }
            }
        }
    }

    .schedule-demo-section {
        .schedule-bg {
            padding: 100px 0;
        }
        .form-outer {
            padding: 50px 30px 120px;
        }
        .schedule-form-block {
            ul {
                li {
                    padding: 0 10px;
                }
            }
        }
    }

    .design-plans-section {
        .design-inner-plans {
            ul {
                li {
                    width: 100%;
                    padding-right: $zero;
                    margin-bottom: $thirty;
                    &:last-child {
                        margin-bottom: $zero;
                    }
                }
            }
        }
        p {
            padding: 0 15px;
        }
    }
    .kitchen-design-solution {
        p {
            padding: 0 15px;
        }
    }
    .howit-work-section {
        .howwork-block {
            li {
                width: 50%;
            }
        }
        p {
            padding: 0 15px;
        }
    }
    .testimonial-container {
        .test-descr {
            margin-bottom: $fourty;
        }
        .client-testimonials {
            padding: $fourty;
            .author-img {
                position: static;
                margin-bottom: $fourty;
            }
        }
        .quote-block {
            display: none;
        }
    }

    .footer-container {
        .footer-top-outer {
            .footer-column {
                width: 50%;
                margin-bottom: $twenty;
            }
        }
        .footer-bottom {
            .footer-bottom-outer {
                justify-content: center;
                .footer-col2,.footer-col1,.footer-col3 {
                    width: 100%;
                    margin-bottom: $fourty;
                }
                .footer-col1 {
                    margin-bottom: 0;
                    order: 3;
                    p {
                        text-align: center;
                    }
                }
                .footer-col2 {
                    order: 2;
                }
                .footer-col3 {
                    order: 1;
                }
                .footer-nav {
                    justify-content: center;
                }
                .social-icons {
                    justify-content: center;
                }
            }
        }
    }
    
}

@media (max-width:$device__m) {
    h1 {
        font-size: $thirty + 2;
        line-height: $fourty;
    }
    h2 {
        font-size: $thirty - 4;
        line-height: $fourty - 4;
    }
    h4 {
        font-size: $twenty;
        line-height: $thirty;
    }
    p {
        font-size: $twenty - 4;
        line-height: $twenty + 4;
        margin-bottom: 20px !important;
    }
    .description-content {
        padding: 0 15px;
    }
    .success-message-block {
        p {
            font-size: $twenty - 4;
            line-height: $twenty + 2;
        }
    }
   
    
    .kitchen-design-solution#solutions-block {
        h2 {
                margin-top: $fifty;
        }
    }
    
    .book-demo-top {
        display: none;
    }
    .common-slider-block {
        .owl-carousel {
            .owl-nav {
                .owl-prev {
                    left: 0;
                }
                .owl-next {
                    right: 0;
                }
            }
        }
        &.work-container { 
            .owl-carousel {
                .owl-dots {
                    display: none;   
                }
            }
        }
    }
    .design-plans-section {
        .design-inner-plans {
            ul {
                li {
                    .plans-inner-block {
                        h4 {
                            padding-left: 0;
                            padding-right: 0;

                        }
                    }
                }
            }
        }
    }

    .banner-container {
        padding-top: $fourty;
        .banner-inner-section {
            .banner-video {
                padding-top: $sixty;
            }
        }
    }

    
    
    .header {
        .header-inner {
            .nav-mobile {
                    display: block;
            }
         }
        .right-button {
            display: none;
            .btn {
                font-size: $ten + 5;
                padding: $ten;
            }
        }
        
        .hambourger-menu {
                position: fixed;
                top: 27px;
                right: 20px;
                display: block;
                z-index: 99;
                span {
                    width: $thirty - 3;
                    height: 3px;
                    background: $blue;
                    display: block;
                    margin-bottom: 5px;
                    transition:0.5s ease;
                    -webkit-transition:0.5s ease;
                    -o-transition:0.5s ease;
                    -moz-transition:0.5s ease;
                    -ms-transition:0.5s ease;
                }
        }
        
        .navigation {
            z-index: 99;
            background: $color_orange;
            position: fixed;
            left: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            max-width: 100%;
            opacity: 0;
            visibility: hidden;
            overflow-y: hidden;
            overflow-x: hidden;
            transition: opacity 0.2s, visibility 0ms linear 0.2s, transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
            transform: translate3d(290px, 0, 0);
            ul {
                li {
                    padding-right: $zero;
                    width: 100%;
                    margin-bottom: 15px;
                    a {
                        font-size: $twenty - 2;
                        color: $blue;
                        display: inline-block;
                        padding-bottom: 0;
                    }
                    ul {
                        padding: 10px 0;
                        li {
                            margin-bottom: $zero;
                        }
                    }
                    &.signin {
                        display: block;
                    }
                }
            }
            .btn {
                display: inline-block;
                background: $color_white;
                border: 0;
                line-height: 37px;
            }
        }
    }
    .howit-work-section {
        .howwork-block {
            margin-bottom: $zero;
        }
    }
    .testimonial-container {
        padding-bottom: 110px;
    }
    .whyus-main-section {
        margin-bottom: $sixty;
        .whyus-block {
            padding-left: $ten + 5;
            padding-right: $ten + 5;
            .left-content {
                width: 100%;
                margin-bottom: $thirty;
            }
            .whyus-listing {
                width: 100%;
                padding-left: $zero;
                li {
                    width: 50%;
                    margin-bottom: $twenty;
                }
            }
        }
    }
    .howit-work-section {
        margin-bottom: $sixty;
        .howwork-block {
            li {
                width: 100%;
            }
        }
    }
    .kitchen-design-solution {
        p {
            padding: $zero;
        }
        .kitchen-solution {
            padding: 20px 0;
            .kitchen-left-content {
                width: 100%;
                margin-bottom: $thirty;
            }
            .kitchen-right-content {
                width: 100%;
                padding-left: $zero;
            }
        }
    }
    .trust-kitchen-design {
        .left-design-block {
            width: 100%;
        }
        .right-design {
            width: 100%;
            margin-top: $seventy;
        }
        .left-design-block {
            .book-demo {
                padding-left: $twenty;
                padding-right: $twenty;
                font-size: $ten + 5;
            }
        }
    }
    .work-container {
        margin: $fourty 0;
        .item {
            padding-left: $fourty;
            padding-right: $fourty;
        }
    }
    .testimonial-container {
        .client-testimonials {
            .author-img {
                display: block;
                text-align: center;
                img {
                    display: inline-block;
                }
            }
        } 
        .owl-carousel {
            .owl-dots {
                left: 0;
            }
        }
    }
    .schedule-demo-section {
        .schedule-bg {
            padding: 60px 0;
        }
        .schedule-form-block {
            h2 {
                margin-bottom: $twenty;
            }
            ul {
                li {
                    width: 100%;
                }
            }
        }
    }
    .testimonial-container {
        .client-testimonials {
            padding: $twenty;
            text-align: center;
        }
    }
    .footer-container {
        .footer-top-outer {
            .footer-column {
                width: 100%;
                margin-bottom: $twenty;
            }
        }
    }
}
}


@media (max-width:$device__m) {
    .landingpage-container {
        .footer-container {
            .footer-bottom {
                p {
                    flex-wrap: wrap;
                    a {
                        margin-top: 20px;
                        margin-left: 0;
                    }
                }
            }
        }
    }
    .menu-active {
        .header {
            .navigation {
                transform: translateZ(-0);
                opacity: 1;
                visibility: visible;
                transition: opacity 0.2s, visibility 0ms, transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
            }
        }
        .hambourger-menu {
            span {
                margin-bottom: $zero !important;
                transition:0.5s ease;
                -webkit-transition:0.5s ease;
                -o-transition:0.5s ease;
                -moz-transition:0.5s ease;
                -ms-transition:0.5s ease;
                &:first-child {
                    transform: rotate(49deg);
                    position: relative;
                    top: 5px;
                    transition:0.5s ease;
                    -webkit-transition:0.5s ease;
                    -o-transition:0.5s ease;
                    -moz-transition:0.5s ease;
                    -ms-transition:0.5s ease;
                }
                &:nth-child(2) {
                    display: none;
                    transition:0.5s ease;
                    -webkit-transition:0.5s ease;
                    -o-transition:0.5s ease;
                    -moz-transition:0.5s ease;
                    -ms-transition:0.5s ease;
                }
                &:last-child {
                    transform: rotate(-51deg);
                    position: relative;
                    top: 2px;
                    transition:0.5s ease;
                    -webkit-transition:0.5s ease;
                    -o-transition:0.5s ease;
                    -moz-transition:0.5s ease;
                    -ms-transition:0.5s ease;
                }
            }
        }
    }
}